<template>
  <form class="form" @submit.prevent="handleSubmit">
    <input hidden autocomplete="username" type="text" :value="email" />

    <div class="form__group column">
      <AppFormInput
        v-model="oldPassword"
        autocomplete="current-password"
        :error="oldPasswordError"
        label="Old password"
        type="password"
        @input="
          serverValidationErrors.oldPassword = '';
          $v.oldPassword.$touch();
        "
        @blur="$v.oldPassword.$touch()"
      />
      <AppFormInput
        v-model="newPassword"
        autocomplete="new-password"
        :error="newPasswordError"
        label="New password"
        type="password"
        @input="
          serverValidationErrors.newPassword = '';
          $v.newPassword.$touch();
        "
        @blur="$v.newPassword.$touch()"
      />
      <AppFormInput
        v-model="newPasswordConfirmation"
        autocomplete="new-password"
        :error="!$v.newPasswordConfirmation.sameAsNewPassword ? 'Passwords must be identical.' : ''"
        label="Repeat new password"
        type="password"
        @input="
          serverValidationErrors.newPassword = '';
          $v.newPasswordConfirmation.$touch();
        "
        @blur="$v.newPasswordConfirmation.$touch()"
      />
    </div>

    <div class="form__group form__btn-wrap">
      <button class="btn border-btn" type="submit" :disabled="$v.$invalid">Save</button>
    </div>

    <Transition name="fade">
      <div class="form-loader-wrapper" v-if="sendingPasswordForm">
        <AppLoader :margin="2" :size="36"></AppLoader>
      </div>
    </Transition>
  </form>
</template>

<script>
import { minLength, required, sameAs } from 'vuelidate/lib/validators';

import updatePassword from '@/graphql/me/account/UpdatePassword.mutation.gql';

import AppFormInput from '@/components/partials/elements/AppFormInput.vue';

export default {
  name: 'AccountChangePasswordForm',
  components: {
    AppFormInput,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      sendingPasswordForm: false,
      serverValidationErrors: {
        oldPassword: '',
        newPassword: '',
      },
    };
  },
  validations: {
    oldPassword: {
      required,
      serverError() {
        return !this.serverValidationErrors.oldPassword;
      },
    },
    newPassword: {
      required,
      minLength: minLength(8),
      serverError() {
        return !this.serverValidationErrors.newPassword;
      },
    },
    newPasswordConfirmation: {
      sameAsNewPassword: sameAs('newPassword'),
    },
  },
  computed: {
    oldPasswordError() {
      if (!this.$v.oldPassword.$error) {
        return '';
      }
      if (this.serverValidationErrors.oldPassword) {
        return this.serverValidationErrors.oldPassword;
      }
      return 'Old password is required.';
    },
    newPasswordError() {
      if (!this.$v.newPassword.$error) {
        return '';
      }
      if (this.serverValidationErrors.newPassword) {
        return this.serverValidationErrors.newPassword;
      }
      return `New password must be at least ${this.$v.newPassword.$params.minLength.min} characters.`;
    },
  },
  methods: {
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid || this.sendingPasswordForm) {
        return;
      }

      this.sendingPasswordForm = true;
      this.resetServerValidation();

      this.$apollo
        .mutate({
          mutation: updatePassword,
          variables: {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
        })
        .then((data) => {
          if (data) {
            this.sendingPasswordForm = false;
            this.oldPassword = '';
            this.newPassword = '';
            this.newPasswordConfirmation = '';
            this.$v.$reset();
            this.resetServerValidation();
            this.$toast.success('Your password was changed successfully.');
            this.$emit('complete');
          }
        })
        .catch((err) => {
          const { graphQLErrors } = err;
          let errorMessage = graphQLErrors[0].message || 'Failed to change your password.';

          if (graphQLErrors[0].extensions?.validation) {
            errorMessage = 'Please check information provided.';

            const errors = graphQLErrors[0].extensions.validation;
            for (let field in errors) {
              const normalizedField = field.replace(/_([a-z])/, (match, v) => v.toUpperCase());
              if (this.serverValidationErrors[normalizedField] !== undefined) {
                this.serverValidationErrors[normalizedField] = errors[field][0];
              }
            }
          }

          this.sendingPasswordForm = false;
          this.$toast.error(errorMessage);
        });
    },
    resetServerValidation() {
      this.serverValidationErrors.oldPassword = '';
      this.serverValidationErrors.newPassword = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  position: relative;

  &__btn-wrap {
    margin-top: 40px;
  }

  &-loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#fff, 0.7);
  }
}
</style>
