



















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { formatDate } from '@/helpers/dateTimeHelper';
import meQuery from '@/graphql/me/Me.query.gql';
import updateMeProfile from '@/graphql/me/account/UpdateUserProfile.mutation.gql';
import { dictionaryCountriesWithRegions } from '@/api/dictionary';

import BackHeader from '@/components/partials/BackHeader.vue';
import FormInput from '@/components/partials/elements/FormInput.vue';
import CollapseTransition from '@/components/transitions/CollapseTransition.vue';
import AccountChangePasswordForm from '@/components/partials/AccountChangePasswordForm.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheArrowDownIcon from '@/components/icons/TheArrowDownIcon.vue';
import PaymentMethodFormSelect from '@/components/partials/profile/PaymentMethodFormSelect.vue';

@Component({
  components: {
    PaymentMethodFormSelect,
    TheArrowDownIcon,
    TheArrowHorizIcon,
    AccountChangePasswordForm,
    CollapseTransition,
    BackHeader,
    FormInput,
  },
  metaInfo: {
    title: 'Account details',
  },
  apollo: {
    me: {
      query: meQuery,
      result(result: any) {
        const user = result?.data?.me;
        if (!user) {
          return;
        }

        this.userData = user;

        if (this.userData.address) {
          this.userDetailsForm.address_line_1 = this.userData.address.line_1;
          this.userDetailsForm.address_line_2 = this.userData.address.line_2;
          this.userDetailsForm.city = this.userData.address.city;
          this.userDetailsForm.country = Number(this.userData.address.country_details.id);
          this.selectedCountry = this.countries.find((c: any) => c.id === this.userDetailsForm.country);
          this.userDetailsForm.postal_code = this.userData.address.postal_code;
          this.userDetailsForm.state = this.userData.address.state;
        }
        this.userDetailsForm.additional_email = this.userData.additional_email;
        this.userDetailsForm.phone = this.userData.phone;
        this.userDetailsForm.additional_phone = this.userData.additional_phone;

        if (this.userData.additional_phone) {
          this.addOtherPhone = true;
        }
      },
    },
  },
})
export default class MyMembership extends Vue {
  userData: any = {};
  userDetailsForm: any = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    country: '',
    postal_code: '',
    state: '',
    additional_email: '',
    phone: '',
    additional_phone: '',
  };

  countries: any[] = [];
  selectedCountry: any = null;
  selectedRegion: any = null;
  addOtherPhone = false;

  sendingDetailsForm = false;

  validationErrors: any = {
    line_1: '',
    line_2: '',
    city: '',
    country_id: '',
    postal_code: '',
    state: '',
    additional_email: '',
    phone: '',
    additional_phone: '',
  };

  isShowChangePasswordFormMobModal = false;
  isChangePasswordFormCollapsed = false;

  get membershipExpiryDate() {
    return formatDate(this.userData.membership.expiry_date);
  }

  get memberSinceDate() {
    return formatDate(this.userData.membership.member_since);
  }

  get memberCardExpiryDate() {
    return formatDate(this.userData.membership.card_expiry);
  }

  get postalCodeLabel() {
    return this.selectedCountry && this.selectedCountry.code === 'US' ? 'ZIP code' : 'Postal code';
  }

  get regionLabel() {
    if (this.selectedCountry && this.selectedCountry.code === 'US') {
      return 'State';
    }
    if (this.selectedCountry && this.selectedCountry.code === 'CA') {
      return 'Province';
    }
    return 'State (Province)';
  }

  @Watch('selectedCountry')
  watchSelectedCountry(selectedCountry: any) {
    if (selectedCountry) {
      this.selectedRegion = selectedCountry.regions.find((r: any) => r.code === this.userData.address.state);
    }
  }

  async created() {
    this.$root.$on('closeModalProfile', this.hideChangePasswordFormMobModal);

    this.countries = await dictionaryCountriesWithRegions();
    if (this.userDetailsForm.country) {
      this.selectedCountry = this.countries.find((c: any) => c.id === Number(this.userDetailsForm.country));
    }
  }

  onCountrySelect() {
    this.validationErrors.country_id = '';
    this.userDetailsForm.country = this.selectedCountry.id;
    this.selectedRegion = null;
    this.userDetailsForm.state = '';
  }

  onRegionSelect() {
    this.validationErrors.state = '';
    this.userDetailsForm.state = this.selectedRegion.code;
  }

  resetValidationErrors(key: string) {
    if (this.validationErrors[key] !== undefined) {
      this.validationErrors[key] = '';
    }
  }

  toggleChangePasswordForm() {
    this.isChangePasswordFormCollapsed = !this.isChangePasswordFormCollapsed;
  }

  afterShowingChangePasswordForm() {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
  }

  showChangePasswordFormMobModal() {
    this.isShowChangePasswordFormMobModal = true;
    this.$store.dispatch('disableScroll');
  }

  hideChangePasswordFormMobModal() {
    this.isShowChangePasswordFormMobModal = false;
    this.$store.dispatch('enableScroll');
  }

  changeAccountDetails() {
    if (this.sendingDetailsForm) {
      return;
    }
    this.sendingDetailsForm = true;
    this.$apollo
      .mutate({
        mutation: updateMeProfile,
        variables: {
          input: {
            address: {
              city: this.userDetailsForm.city,
              country_id: this.userDetailsForm.country,
              line_1: this.userDetailsForm.address_line_1,
              line_2: this.userDetailsForm.address_line_2,
              postal_code: this.userDetailsForm.postal_code,
              state: this.userDetailsForm.state,
            },
            additional_email: this.userDetailsForm.additional_email,
            additional_phone: this.userDetailsForm.additional_phone,
            phone: this.userDetailsForm.phone,
          },
        },
      })
      .then(() => {
        this.sendingDetailsForm = false;
        this.$toast.success('Your data were saved successfully.');
      })
      .catch((err: any) => {
        const { graphQLErrors } = err;
        if (graphQLErrors[0].extensions?.validation) {
          const errors = graphQLErrors[0].extensions.validation;
          for (let field in errors) {
            const fieldName = field.replace(/(input(\.address)?)\./, '');
            if (this.validationErrors[fieldName] !== undefined) {
              this.validationErrors[fieldName] = errors[field][0];
            }
          }
        }
        this.sendingDetailsForm = false;
        this.$toast.error('Failed to save your data.');
      });
  }
}
