<template>
  <label v-bind="$attrs" class="label" :class="{ up: moveToUp }" @click="$emit('click')">{{ label }}</label>
</template>

<script>
export default {
  name: 'PaymentMethodFormLabel',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    moveToUp: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #acacac;
  white-space: nowrap;
  transition: all 0.3s linear;

  @media (max-width: 767px) {
    display: flex;
    top: 15px;
    align-items: center;
    font-size: 18px;
  }

  &.up {
    top: -15px;
    font-size: 14px;
  }
}
</style>
