<template>
  <p v-if="error" class="add-card-form__error text-danger">{{ error }}</p>
</template>

<script>
export default {
  name: 'PaymentMethodFormError',
  props: {
    error: {
      type: String,
      required: true,
    },
  },
};
</script>
