

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormInput extends Vue {
  @Prop() label!: string;
  @Prop() value!: string | number;
  @Prop() valueInput: string;
  @Prop({ default: false, type: [Boolean, String] }) autocomplete: string | boolean;
  @Prop({ default: '' }) error: string;
  @Prop({ default: '' }) icon: string;
  @Prop({ default: false, type: Boolean }) readonly: boolean;
  @Prop({ default: false, type: Boolean }) required: boolean;
  @Prop({ default: 'text' }) type: string;

  public $refs!: {
    input: any;
  };

  public active = false;

  created() {
    this.active = Boolean(this.value);
  }

  startText() {
    this.active = true;
    this.$refs.input.focus();
  }

  finishText() {
    if (!this.value) {
      this.active = false;
    }
  }
}
